//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-888:_1228,_7728,_832,_1568,_4920,_2344,_3204,_3008;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-888')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-888', "_1228,_7728,_832,_1568,_4920,_2344,_3204,_3008");
        }
      }catch (ex) {
        console.error(ex);
      }